/*
* 업무구분: 컨설팅  
* 화면 명: 헬스케어서비스
* 화면 설명: 헬스케어서비스,눈건강체크,영양분석서비스, 더헬스건강세미나 등의 고객 서비스관리
* 화면 접근권한:
*/
<template>
  <ur-page-container class="msp" title="헬스케어서비스" :show-title="true" type="subpage" @on-header-left-click="fn_HistoryBack" >
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap">
      <!-- 상단 검색영역 box -->
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="visual-top-box">
        <div class="visual-info">
          <strong class="tit">삼성생명 FC는 헬스케어서비스로 고객님의 무병장수<span class="fs14">(無病長壽)</span>인생과 함께 합니다.</strong>
        </div>
      </ur-box-container>
      <!-- 상단 검색영역 box  //-->

      <!-- 검색영역 : 고객명 검색영역  -->
      <ur-box-container v-if="checkDisabled" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-input-box msp-srh-box">
        <mo-text-field v-model="CustNm" searchable underline placeholder="고객명을 입력하세요" class="nw-input"  ref="editCustNmRef"  @click-icon="fn_OpenMSPCM005P"/>
      </ur-box-container>
      <!--  검색영역 : 고객명 검색영역 //-->

      <!-- 고객명 검색후 재검색 후 화면영역: 000 고객님 환영합니다. 재검색 버튼 입니다.고객명 검색후 재검색 영역  msp-checkCon-list//-->

      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="add-box msp-srh-box">
        <span class="srh-txt"> {{ CustNm }} 고객님 환영합니다. </span>
        <mo-button  componentid="mo_button_002" color="primary" shape="border" size="small" class="ns-btn-round white" @click="onClickSearch">재검색</mo-button>
      </ur-box-container>
        
      <!-- 스크롤 List -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area bd-T-Ty1 pb80">      
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-radio-list2 type-2 msp-checkCon-list">
          <mo-list :list-data="mokupdata">
           <!--<template #list-item="{item, index}">
              <mo-list-item :class="{'on' : item.checked  === true}">   
                <mo-checkbox v-model="item.checked"  @input="fn_CheckboxClick(index)" :disabled="checkDisabled"/> --->
           <template #list-item="{item, index}">
              <mo-list-item >        
                <mo-radio v-model="radio" :value="String(index + 1)" :disabled="radioCheck" @input="fn_CheckboxClick()" />
                <div class="list-item__contents" @click="fn_CheckboxClick1(String(index + 1))" >
                  <div class="list-item__contents__title">
                    <span class="title txtSkip">{{item.data1}}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <span class="top-txt">{{item.data2}}</span>
                    <span class="crTy-bk7">{{item.data3}} </span>
                    <span class="txt-bottom-info pt10 fs12 crTy-bk7 dsD w100" v-if="item.data4"><b class="tit">최근이용일자 : </b> {{$bizUtil.dateYyMmDdFormat(item.data4).replaceAll('.', '-')}}</span>
                    <span class="txt-bottom-info pt10 fs12 crTy-bk7 dsD w100" v-if="item.data5"><b class="tit"></b>  {{item.data5}}</span>
                    <mo-button componentid="mo_button_002" v-if="item.data6" color="primary" shape="border" size="small" class="ns-btn-round black btn-list" :disabled="guideCheck" @click="fn_eyeAppOpen()"> {{item.data6}}</mo-button>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
          
        </ur-box-container>
      </ur-box-container>
      <!-- 스크롤 List -->

      <!-- 바텀시트1 : 버튼영역 -  URL문자발송, 테스트시작, 알아보기 버튼 표출 -->
      <!--  [버튼 표출 성명입니다.]
       버튼 구현 설명 : 각 버튼 한개 표출시에는 <mo-button> 한개만 들어가면 되고, 2개표출시에는 <mo-button>버튼 2개 넣어주셔서 사용하시면 됩니다.
       버튼명 수정해서 사용하시면 되는데, 혹시 몰라서 '알아보기' 버튼도 참고차 넣어두었습니다.

       1. 헬스케어서비스 클릭시 : 'URL문자발송' 버튼만 표출
       2. 눈건강체크 클릭시 : '테스트시작' 버튼만 표출
       3. 영양분석서비스 클릭시 : 'URL문자발송' 및 '테스트시작' 2개 버튼 표출
       4. 더헬스 건강세미나 클릭시: '알아보기' 버튼만 표출 입니다.
       -->

      <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet msp-bottomSt-buttonTy1">
        <div class="content-area">
           <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative ns-btn-ty1">
              <div class="relative-div">
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" text="URL 문자발송" v-if="btnTextGb === '1'" @click="fn_SmsClick('1')">URL 문자발송</mo-button>
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" text="테스트시작" v-if="btnTextGb === '2'" @click="fn_eyeClick()">테스트시작</mo-button>
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" text="알아보기" v-if="btnTextGb === '4'" @click="fn_seminaClick()">알아보기</mo-button>
              </div>
            </ur-box-container>
        </div>
      </mo-bottom-sheet>

     <mo-bottom-sheet ref="urlsms_test_bottomsheet" class="ns-bottom-sheet msp-bottomSt-buttonTy1">
        <div class="content-area">
           <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative ns-btn-ty1">
              <div class="relative-div">
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" v-if="btnTextGb === '3'" @click="fn_SmsClick('2')">URL 문자발송</mo-button>
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" v-if="btnTextGb === '3'" @click="fn_UrlWebClick()">테스트시작</mo-button>
              </div>
            </ur-box-container>
        </div>
      </mo-bottom-sheet>


      <!-- 바텀시트2 : 문자 발송완료 Alert 팝업입니다. -->
      <mo-bottom-sheet ref="sms_nsbottomsheet"  class="ns-bottom-sheet msp-bottomSt-alert">
        <div class="customer-info-txt txt-center">
          <p class="ment">발송이 완료되었습니다.</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_smsCheckclose" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <!-- 바텀시트3 : 모바일에서 눈건강체크 테스트 실행시 해당 메시지 노출 Alert 팝업입니다. -->
      <mo-bottom-sheet ref="eye_nsbottomsheet"  class="ns-bottom-sheet msp-bottomSt-alert">
        <div class="customer-info-txt txt-center">
          <p class="ment">태블릿에서만 활용 가능한 메뉴입니다.</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_eyeCheckclose" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <!-- 바텀시트4 :  URl문자발송 클릭시 마케팅동의 미고객의 경우 메시지 표출  Alert 팝업입니다. -->
      <mo-bottom-sheet ref="mkt_nsbottomsheet"  class="ns-bottom-sheet msp-bottomSt-alert">
        <div class="customer-info-txt txt-center">
          <p class="ment">마케팅동의가 되어 있지 않아 문자를 전송할 수 없습니다.</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_mktCheckclose" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <!-- 바텀시트5 :  URl문자발송 클릭시 이캐쉬가 부족할 경우 발송실패 메시지 표출  Alert 팝업입니다. -->
      <mo-bottom-sheet ref="ecash_nsbottomsheet"  class="ns-bottom-sheet msp-bottomSt-alert">
        <div class="customer-info-txt txt-center">
          <p class="ment">이캐쉬가 부족합니다. 충전 후 사용해주세요.</p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ecashCheckclose" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

  <!-- Toast -->
      <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

    </ur-box-container>  
  </ur-page-container>
</template>
<script>
/************************************************************************************************
 * 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import moment from 'moment'
import MSPCM005P from '@/ui/cm/MSPCM005P' // 고객 목록 검색
import MSPCM128P from '@/ui/cm/MSPCM128P' // 고객수신목록(문자발송)
import MSPCM153P from '@/ui/cm/MSPCM153P' // 문자발송선택
import MSPCM154P from '@/ui/cm/MSPCM154P' // 터치온문자발송 

import piCommonUtil from '@/ui/pi/common/piCommonUtil'
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT800M",
    screenId: "MSPCT800M",
    components: {},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        userInfo: {}, // 사용자정보 obj
        lv_cnsltNo: '', // 컨설턴트 번호
        isMngr: false, // 지점장 여부

        radio: '', // 라디오 변수
        item: [],
        selectedItem: {}, // 선택한 값 Obj        
        checkDisabled: true,
        guideCheck: true, //  선택
        radioCheck: true, //  radio 버튼
        popup005: {}, // MSPCM0052P 고객 목록 검색
        toastMsg: '', // 문자발송 toast 문구
        btnTextGb: '', //서비스선택구분 
        pblYmd: '-',  //눈건강체크이용일
        pblYmdTheHealth: '-', //헬스체크이용일
        trSmsGb: '',
        
        CustNm: '',   //고객명
        ChnlCustId: '',  //채널고객ID  
        Aage: '',         //연령
        Celno: '',       //핸드폰
        custListSms: [], //문자발송용 고객목록 
       popup128: {}, // MSPCM128P 수신고객목록
       popup153: {}, // 문자발송선택
       popup154: {}, // 터치온고객목록
             // 카테고리
      navItems: [
        {
          tabTitle: '홈',
          component: 'TSSSP201M-tab',
          item: {}
        }
      ],
      lv_UntySlmtClsf: [],

        mokupdata: [{
            data0:'1',
            data1: '헬스케어서비스',
            data2: '삼성생명만의 더 확실한 건강관리 앱, 더 헬스를 만나보세요.',
            data3: '고객님의 건강자산을 지켜드리는 더 효과적인 서비스 "THE Health"를 안내해 보세요.',
            data4: '',
            data5: ''
          },
          {
            data0: '2',
            data1: '눈건강체크',
            data2: '간단한 테스트로 눈 건강 확인하고, 미리 관리하세요.',
            data3: '본 테스트의 결과는 의학적 소견이 아닙니다. 정확한 측정은 병원이나 안경원을 방문하여 진료 받을 필요가 있습니다.',
            data4: '',
            data5: '서비스제공 : (주)픽셀로 ',
            data6: ' 안내자료 '
          },
          {
            data0: '3',
            data1: '영양분석서비스',
            data2: '내 몸에 필요한 영양성분 궁금하시죠?',
            data3: '몇 가지 질문에 답하고 나에게 필요한 영양성분을 알아보세요. (약 3분 소요)',
            data4: '',
            data5: '서비스제공 : (주)케어위드 '
          },
          // {
          //   data0: '4',
          //   data1: '더헬스 건강세미나',
          //   data2: '삼성생명만의 특별한 체험형 세미나로 고객과 접점을 만드세요.',
          //   data3: '당뇨병 전문의가 말하는 혈당관리 중요성 특강과 채혈없이 혈당을 측정하는 디지털 의료기기를 무료로 경험할 수 있는 체험형 세미나 입니다.',
          //   data4: '',
          //   data5: ''
          // },
        ],
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    created() {

      // backKey event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

      // 사용자 정보
        this.userInfo = this.getStore('userInfo').getters.getUserInfo
        this.lv_cnsltNo = this.userInfo.userId // 컨설턴트 번호

      // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
        if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
         this.isMngr = true
        }      
        
        if ( this.$route.params.chnlCustId != null  &&  his.$route.params.chnlCustId != "undefined"  &&
             this.$route.params.custNm != null  &&  his.$route.params.custNm != "undefined")  {
              this.ChnlCustId = this.$route.params.chnlCustId
              this.CustNm = this.$route.params.custNm
        }
    },

    computed: {
      checkCount () {
     }

    },
/** watch 정의 영역 */
  watch: {    
       checkCount () {         
       },  
  },    
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    mounted () {

      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog(this.$options.screenId)

      if(!this.$bizUtil.isEmpty(this.$route.params)){
        // 파라미터 세팅
        if (this.$route.params.isExistCustYn === 'Y') {
            this.CustNm = this.$route.params.custNm  //고객명
            this.ChnlCustId = this.$route.params.chnlCustId   //채널고객ID
            this.Age = this.$route.params.age        //연령
            this.Celno = this.$route.params.celno   //핸드폰
            this.custListSms[0] = this.$route.params.custInfoVO
        }
        this.fn_healthCareSvcUseDtSrch()
        this.$refs['editCustNmRef'].focus()
      }
    },

/** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
        // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

  },
  
  
    methods: {
      // 고객 헬스케어버비스 이용일자 조회 
      fn_healthCareSvcUseDtSrch() {
        if ( this.CustNm !== '') {
          this.checkDisabled = false 
          this.radioCheck = false 
          this.guideCheck = false               
          this.fn_healthCareLog('01')     // 헬스케어서비스 로그등록
          this.fn_eyeHealthCareRntPrcusHstr()  // 눈건강헬스체크 이용일자 조회 
          this.fn_theHealthSmsRntPrcusHstr()   // the 헬스 이용일자 조회           
        }
      },
    /******************************************************************************
    * Function명 : fn_CheckboxClick
    * 설명       : 체크박스 클릭  filter(item => item.checked)
    ******************************************************************************/
      fn_CheckboxClick () {
 
        this.btnTextGb = this.radio
        console.log('this.btnTextGb>>', this.btnTextGb)

         if ( this.btnTextGb === '3') {
              this.$bizUtil.showCustomBottomSheet(this, this.$refs.nsbottomsheet, false)
              this.$bizUtil.showCustomBottomSheet(this, this.$refs.urlsms_test_bottomsheet, true)
          } else {
              this.$bizUtil.showCustomBottomSheet(this, this.$refs.nsbottomsheet, true)
              this.$bizUtil.showCustomBottomSheet(this, this.$refs.urlsms_test_bottomsheet, false)
          }
 
      },
      fn_CheckboxClick1 (idx) {
 
        this.btnTextGb = idx
        this.radio = idx
        console.log('this.btnTextGb?????>>', this.btnTextGb)

         if ( this.btnTextGb === '3') {
              this.$bizUtil.showCustomBottomSheet(this, this.$refs.nsbottomsheet, false)
              this.$bizUtil.showCustomBottomSheet(this, this.$refs.urlsms_test_bottomsheet, true)
          } else {
              this.$bizUtil.showCustomBottomSheet(this, this.$refs.nsbottomsheet, true)
              this.$bizUtil.showCustomBottomSheet(this, this.$refs.urlsms_test_bottomsheet, false)
          }
 
      },

   /*********************************************************
     * Function명: fn_OpenMSPCM005P
     * 설명: 고객명으로   고객검색 팝업 호출 (MSPCM005P)
     *        - 마케팅동의 고객만 제공 받도록 호출함.  (  useMktCheck: true   // 마케팅동의 여부 체크 )
     *********************************************************/
      fn_OpenMSPCM005P () {
        
        let  sCustName = this.CustNm.trim()
        if (sCustName === null || sCustName === '') { 
              this.getStore('confirm').dispatch('ADD', '고객명을 입력하시기 바랍니다.')
            return
          }
        
        const lp_Vm = this  
        this.popup005 = this.$bottomModal.open(MSPCM005P, {
          properties: {
            pPage: 'MSPCT800M',
            pCustNm: sCustName, // 고객명
            useMktCheck: true   // 마케팅동의 여부 체크 
          },
          listeners: {
            confirmPopup: (pData) => {
              // 모달 닫기
              lp_Vm.$bottomModal.close(lp_Vm.popup005)  
              // 파라미터 세팅
               this.fn_MSPCM005P_Parm(pData)  
              // 고객 헬스케어버비스 이용일자 조회 
              this.fn_healthCareSvcUseDtSrch()
              // if ( lp_Vm.CustNm !== '') {
              //       this.checkDisabled = false 
              //       this.radioCheck = false 
              //       this.guideCheck = false               
              //       this.fn_healthCareLog('01')     // 헬스케어서비스 로그등록
              //       this.fn_eyeHealthCareRntPrcusHstr()  // 눈건강헬스체크 이용일자 조회 
              //       this.fn_theHealthSmsRntPrcusHstr()   // the 헬스 이용일자 조회           
              //  }
            }
          }
       })
      },
    
      fn_MSPCM005P_Parm (prdt) {
        if (prdt != null) {
          if (prdt.isExistCustYN === 'Y') {
              this.CustNm = prdt.custNm  //고객명
              this.ChnlCustId = prdt.chnlCustId   //채널고객ID
              this.Age = prdt.age        //연령
              this.Celno = prdt.celno   //핸드폰
              this.custListSms[0] = prdt
          }
        }
      },
    /******************************************************************************
    * Function명  : fn_healthCareLog
    * 설명        : 헬스케어 활용로그
    *               healthCode :  '01'  -  헬스케어서비스 활용로그  
    *                             '02'  -  영양분석서비스 
    *                             '06'  -  건강세미나 서비스 ?
    *                             '04'  -  눈건강 최근이용일자 조회
    *                             '05'  -  더헬스 최근 이용일자조회 
    ******************************************************************************/
    fn_healthCareLog (healthCode) {
      //this.isTSSCM204P = false
      let lv_Vm = this
      const trnstId = 'txTSSSA11P12'
      const auth = 'I'
      let pParams = {custNm: lv_Vm.CustNm, chnlGCustId: lv_Vm.ChnlCustId, untySlmtBasInfoNo: healthCode}

      if (healthCode === '01') { // 메인 활용로그
        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
          if (response.body !== null && response.body !== '') {
            if (response.body.trtScCd === 'Y') { // 성공메시지
              // lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              // lv_Vm.getStore('confirm').dispatch('ADD', '성공적으로 저장되었습니다')
            }
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      } else {
        if (this.CustNm !== undefined && this.CustNm !== '') {
          if (this.ChnlCustId !== undefined && this.ChnlCustId !== '') {
            this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
              if (response.body !== null && response.body !== '') {
                if (response.body.trtScCd === 'Y') { // 성공메시지
                  if (healthCode === '04') {
                    lv_Vm.fn_eyeHealthCareRntPrcusHstr()
                  } else if (healthCode === '05') {
                    lv_Vm.fn_theHealthSmsRntPrcusHstr()
                  }
                }
              }
            }).catch(function (error) {
              window.vue.error(error)
            })
          }
        }
      }
    },
   /******************************************************************************
    * Function명  : fn_theHealthSmsRntPrcusHstr
    * 설명        : 더 헬스 문자발송 이용 일자 조회
    ******************************************************************************/
      fn_theHealthSmsRntPrcusHstr () {
        let lv_Vm = this
        const trnstId = 'txTSSSA11S3'
        const auth = 'S'
        let pParams = {custNm: lv_Vm.CustNm, chnlGCustId: lv_Vm.ChnlCustId}

        this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
           if (response.body !== null && response.body !== '') {
            if (response.body.trtScCd === 'Y') { // 성공메시지
              lv_Vm.mokupdata[0].data4  = response.body.pblYmd   //더 헬스 문자발송 이용 일자
            }
          }
       }).catch(function (error) {
          window.vue.error(error)
       })
         console.log( 'pblYmdTheHealth' + lv_Vm.pblYmd  )
      },    
    /******************************************************************************
    * Function명  : fn_eyeHealthCareRntPrcusHstr
    * 설명        : 눈건강 헬스케어 최근 이용 일자 조회
    ******************************************************************************/
    fn_eyeHealthCareRntPrcusHstr () {
      let lv_Vm = this
      const trnstId = 'txTSSSA11S1'
      const auth = 'S'
      let pParams = {custNm: lv_Vm.CustNm, chnlGCustId: lv_Vm.ChnlCustId}

      this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
        if (response.body !== null && response.body !== '') {
          if (response.body.trtScCd === 'Y') { // 성공메시지
            lv_Vm.mokupdata[1].data4  = response.body.pblYmd   // 눈건강 헬스케어 최근 이용 일자
          }
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },    

   /******************************************************************************
     * Function명 : fn_SmsClick
     * 설명       : 문자 수신고객목록 팝업 오픈(MSPCM128P)
     ******************************************************************************/
    fn_SmsClick(smsGb) {

      if ( (this.CustNm === undefined || this.CustNm === '') ||
           (this.ChnlCustId === undefined || this.ChnlCustId === '')  ) {   
            this.getStore('confirm').dispatch('ADD', '고객을 재검색한후 서비스를 선택하십시오.')
            return
      }  
      if   (this.Celno === undefined || this.Celno === '')  {   
            this.getStore('confirm').dispatch('ADD', '고객 전화번호를 확인하십시오.')
            return
      }         
       
      // 문자/이메일/DM/택배 클릭시 호출(이벤트 최종 목적지 배분)
        let targetType = 's'
        let custListObj = [] // 문자수신 고객 Obj
        this.trSmsGb = smsGb
        if (this.trSmsGb !== '1') {    // '1'-더 헬스  .  '2'-영양분석 
            this.trSmsGb = '2'
        } 
        // let param = {
        //   selectList: this.custListSms  
        // }
        custListObj = [
        { targetType: targetType, parentsViewId: 'MSPCT800M', custList: this.custListSms}
        ]

        // 문자/이메일/DM/택배 클릭시 호출(이벤트 최종 목적지 배분) 'MSPCM115M'
        this.fn_OpenMSPCM153P(this, custListObj, this.trSmsGb)


        // 문자발송 버튼  버텀 싯트  제거   
        if ( this.btnTextGb === '1' ) {
              this.$bizUtil.showCustomBottomSheet(this, this.$refs.nsbottomsheet, false)
            } else {
              this.$bizUtil.showCustomBottomSheet(this, this.$refs.urlsms_test_bottomsheet, false)
            }  
      },
   /******************************************************************************
     * Function명 : fn_eyeClick
     * 설명       : 눈건강체크 클릭 ( 눈건강 체크는 태블릿에서만 가능 )
     ******************************************************************************/
    fn_eyeClick () {
      // 눈건강 테스트 는  태블릿만 가능  안내 시트  메세지 출력 
         let lv_Vm = this
         lv_Vm.isTabletYn = false
         piCommonUtil.fn_SetMobileEnv().then(function (response) {
           console.log('태블릿 여부 체크(T만 태블릿)>>>>', response)
           lv_Vm.isTabletYn = response === 'T'

            console.log('태블릿 여부 체크(T만 태블릿 lv_Vm.isTabletYn )>>>>', lv_Vm.isTabletYn)
            if (!lv_Vm.isTabletYn) { 
                lv_Vm.$bizUtil.showCustomBottomSheet(lv_Vm, lv_Vm.$refs.eye_nsbottomsheet, true)
            } else {
                lv_Vm.fn_healthCareLog('04')
                //lv_Vm.$MenuManager.fn_SendIntentPixelro()
                //fn_SendIntentPixelro: function (pParams) {
                let t_SendIntentObj = null
                let t_AppNm = ''
                let t_EncData = ''
                try {
                       t_AppNm = 'com.pixelro.nenoonssample'     // 눈건강(픽셀로) 쪽으로 Intent Send Scheme 정의 - 2021.09.13 [ASR210800347]
                       t_SendIntentObj = {packageName: t_AppNm, key: 'token', data: t_EncData}
                       window.fdpbridge.exec('intentChromeBrowserPlugin', t_SendIntentObj, () => {}, () => {})
                       //window.fdpbridge.exec('intentExtraPlugin', t_SendIntentObj, () => {}, () => {})
                  } catch (error) {}           
            } 
         })
         this.$bizUtil.showCustomBottomSheet(this, this.$refs.nsbottomsheet, false)
    },
    /******************************************************************************
    * Function명 : fn_eyeAppOpen
    * 설명       : 눈건강체크 App오픈 (안내자료 pdf)
    ******************************************************************************/
    fn_eyeAppOpen() {

      this.fn_healthCareLog('04')
 
      this.fn_SelCate()
      console.log(this.lv_UntySlmtClsf)
      console.log(this.getStore('spStore').state.clsfSytmCntntList)
      
      let item1List = []
      let item2List = []
      let item1 = {}
      let item2 = {}
      let item3 = {}
      let pushObj = {}
      let tmpIdx = 0
      let item3Data = false


      item1List = this.getStore('spStore').state.clsfSytmCntntList

      if(item1List === undefined || item1List === null || item1List.length === 0){
        this.getStore('confirm').dispatch('ADD', '안내자료 분류별보기를 확인하십시요 .')
        return
      }

      // for(let i = 0; i < item1List.length; i++) {
      //   // 대분류 중 추천자료 리스트 추출
      //   if(item1List[i].clsfSytmId === '0500000000'){
      //     item1 = item1List[i]
      //       console.log('대분류 >>', item1)

      //     item2List = item1List[i].sAMClsfSytmCntntVO
      //     console.log('중분류 리스트 >>', item2List)
      //     break
      //   }
      // }  

      // tmpIdx = 2
      // item2 = item2List[tmpIdx]
      // console.log('중분류 >>', item2)

      // item3 = item2List[tmpIdx].tTSA00003DVO[2]
      // item3.envCntnt = '0503031HEC'
      // console.log('소분류 >>', item3)
      // console.log('소분류 리스트>>', item2List[tmpIdx].tTSA00003DVO)
      // pushObj.item1 = item1
      // pushObj.item2 = item2
      // pushObj.item3 = item3



      for(let i = 0; i < item1List.length; i++) {
         // 대분류 중 추천자료 리스트 추출
         if(item1List[i].clsfSytmNm === '친숙'){
           item1 = item1List[i]
             console.log('대분류 >>', item1)

           item2List = item1List[i].sAMClsfSytmCntntVO
           console.log('중분류 리스트 >>', item2List)
           break
         }
       } 

      if(item2List === undefined || item2List === null || item2List.length === 0){
        this.getStore('confirm').dispatch('ADD', '안내자료 분류별보기의 헬스케어료를 확인하십시요 .')
        return
      }

      for(let i = 0; i < item2List.length; i++) {
         // 대분류 중 추천자료 리스트 추출
         if(item2List[i].clsfSytmNm === '헬스케어'){
           console.log('중분류 리스트 index >>', item2List)
           tmpIdx = i
           break
         }
       } 

      item2 = item2List[tmpIdx]
      console.log('중분류 >>', item2)

        for(let i = 0; i < item2.tTSA00003DVO.length; i++) {
         // 소분류 추천자료 리스트 추출
         console.log('중분류 리스트 index >>',item2.tTSA00003DVO[i].envCntnt.substring(6, 10))
         if(item2.tTSA00003DVO[i].envCntnt.substring(6, 10) === '1HEC'){
            item3 = item2.tTSA00003DVO[i]
           console.log('소분류 >>', item3)
           item3Data = true
           break
         }
       } 

     if(!item3Data){
        this.getStore('confirm').dispatch('ADD', '안내자료 분류별보기의 헬스케어/눈건강체크를 확인하십시요 .')
        return
      }       

      console.log('소분류 >>', item3)
      console.log('소분류 리스트>>', item2List[tmpIdx].tTSA00003DVO)
      pushObj.item1 = item1
      pushObj.item2 = item2
      pushObj.item3 = item3


      //this.$bizUtil.showCustomBottomSheet(this, this.$refs.bs10, false)
      this.$router.push({
        name: 'MSPSA207M',
        params: {
          callerView: 'MSPCT800M',
          lv_SelectedCateObject: pushObj
        }
      })

    },
    /*********************************************************
     * Function명: fn_SelCate
     * 설명: 분류 목록 조회
     * Params: N/A
     * Return: 대분류/중분류/소분류 구조의 전체 분류목록
     *********************************************************/
    fn_SelCate() {
      let lv_vm = this

      // 업무config의 분류목록 중 안내자료 분류목록 최상단의 키값을 파라미터로 설정 'SACLSFS'라는 값은 스마트폰의 대,중,소 분류를 가져오기 위함.
      let pParams = {clsfSytmId: 'SACLSFS'} // 테블릿. 'SACLSFT'

      // 트랜잭션ID 설정
      // 한번에 안내자료의 분류목록을 모두 읽음.
      let trnstId = 'txTSSSA01S4'
      const _auth = 'S'

      // 서비스 요청
      this.post(lv_vm, pParams, trnstId, _auth)
        .then(function (response) {
          if (lv_vm.$commonUtil.isSuccess(response)) {
            // 서비스 후처리
            if (response.body !== null && response.body !== '') {
              // 응답 데이터 처리
              let responseBody = response.body
              // 전체 분류목록 저장
              lv_vm.lv_UntySlmtClsf = responseBody.sALClsfSytmCntntVO
              // 카테고리 생성
              lv_vm.setCateMenu()
              // 분류 목록 조회후 Vuex에 해당 내용을 저장
              // 안내자료 홈을 제외한 화면에서는 Vuex에 저장된 분류 목록으로 화면 구성
            }
          }
        }, function (error) {
          // 서버 에러 처리
          return Promise.reject(error)
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /*********************************************************
     * Function명: setCateMenu
     * 설명: 카테고리 메뉴 세팅후 자료조회 컨트롤
     * Params: N/A
     * Return: N/A
     *********************************************************/
    setCateMenu () {
      console.log('카테고리' , this.lv_UntySlmtClsf)

      let lv_vm = this
      let bList = lv_vm.lv_UntySlmtClsf
      let tmpTabId
      for (let key in bList) {
        if ( (Number(key) + Number(1) ) < Number(10) ) tmpTabId = '0' + (Number(key) + Number(1) )
        else tmpTabId = (Number(key) + Number(1) )
        let tmp = {
          // 대분류
          tabTitle: bList[key].clsfSytmNm,
          // 아이디 분류값 다시확인
          tabId: tmpTabId,
          component: 'TSSSP207M',
          item: bList[key].sAMClsfSytmCntntVO// 객체별 불러야 할 화면 넣기.(동적)
        }
        lv_vm.navItems.push(tmp)

      }
      //스토어에 카테고리 등록 (As-Is의 시점차이로 인한 위치변경)
      lv_vm.getStore('spStore').dispatch('REGISTER', lv_vm.lv_UntySlmtClsf)

    },
     /******************************************************************************
    * Function명 : fn_UrlWebClick
    * 설명       : 영양분석서비스시작하기 (웹페이지 연계) intentChromeBrowserPlugin
    ******************************************************************************/
    fn_UrlWebClick () {
     
      if ( (this.lv_cnsltNo === undefined || this.lv_cnsltNo === '') ||
           (this.ChnlCustId === undefined || this.ChnlCustId === '') ) {
            this.getStore('confirm').dispatch('ADD', '고객을 재검색한후 서비스를 선택하십시오.')
            return
      }  

      let lv_Url = ''
      this.userEno = this.getStore('userInfo').getters.getUserInfo.userId
      this.encUserEno = this.userEno
      this.encMobslChnlCustId = this.ChnlCustId
      lv_Url = 'https://pilly.in/jka6t'
      if (this.$commonUtil.isMobile()) { // 모바일에서 작동
          // window.fdpbridge.exec('cryptoAESPlugin', {encryptFlag: 'enc', inputText: this.lv_cnsltNo},
          // (result) => {
          //   this.encUserEno = result.data
          //   window.fdpbridge.exec('cryptoAESPlugin', {encryptFlag: 'enc', inputText: this.ChnlCustId},
          //     (result) => {
          //       this.encMobslChnlCustId = result.data
                if (process.env.NODE_ENV === 'production') {
                  lv_Url = 'https://pilly.in/im2bb'
                } else {
                  lv_Url = 'https://pilly.in/jka6t'
                }
                //window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_Url}, () => {}, () => {})
                window.fdpbridge.exec('intentChromeBrowserPlugin', {uri: lv_Url}, () => {}, () => {})
          //     }, () => {})
          // }, () => {})
      } else { // PC에서 작동
        window.open(lv_Url, '_blank')
      }
      this.fn_healthCareLog('02')
      this.$bizUtil.showCustomBottomSheet(this, this.$refs.urlsms_test_bottomsheet, false)
    },
    
     /******************************************************************************
    * Function명 : fn_seminaClick
    * 설명       : 더헬스건강세미나  (웹페이지 연계)
    ******************************************************************************/
    fn_seminaClick () {
     
      if ( (this.lv_cnsltNo === undefined || this.lv_cnsltNo === '') ||
           (this.ChnlCustId === undefined || this.ChnlCustId === '') ) {
            this.getStore('confirm').dispatch('ADD', '고객을 재검색한후 서비스를 선택하십시오.')
            return
      }  

      let lv_Url = ''
      this.userEno = this.getStore('userInfo').getters.getUserInfo.userId
      this.encUserEno = this.userEno
      this.encMobslChnlCustId = this.ChnlCustId
      lv_Url = 'https://www.samsunglife.com/s/svz'
      if (this.$commonUtil.isMobile()) { // 모바일에서 작동
          window.fdpbridge.exec('intentChromeBrowserPlugin', {uri: lv_Url}, () => {}, () => {})
      } else { // PC에서 작동
        window.open(lv_Url, '_blank')
      }

      //this.fn_healthCareLog('06')  // '06'-채널 코드 미반영됨

      this.$bizUtil.showCustomBottomSheet(this, this.$refs.nsbottomsheet, false)
    },  
/*********************************************************
  * Function명: fn_OpenMSPCM153P
  * 설명: 발송서비스 선택 팝업 오픈
  * 
  *********************************************************/
  fn_OpenMSPCM153P(el, custListObj, itemValue) {
   const lv_Vm = el
   
   // itemValue: MSPCM153P를 직접 호출하면서, 메시지 종류를 특정하고 진입하는 경우
   let popup153 = lv_Vm.$bottomModal.open(MSPCM153P, {
     properties: {
       pChnlCustIds: custListObj,
       pItemValue: itemValue
     },
     listeners: {
       onPopupClose: (pData) => {
         // 모달 닫기
         lv_Vm.$bottomModal.close(popup153)
       },
       onNextService: async (pData) => {
         if (!_.isEmpty(pData)) {
           custListObj[0].pMsg = pData.msg  // 선택한 상용구
           custListObj[0].targetType = pData.sendType
         }
 
         if (custListObj[0].custList.length > 0) {
           // 모달 닫기
           await lv_Vm.$bottomModal.close(popup153)
           // 수신고객이 선택되어 있는 경우, 수신고객목록(MSPCM128P) 팝업 오픈
           await lv_Vm.fn_OpenMSPCM128P(lv_Vm, custListObj)
         } else {
           // 모달 닫기
           await lv_Vm.$bottomModal.close(popup153)
         }
         
       },
     }
   })
 },
  /*********************************************************
 * Function명: fn_OpenMSPCM128P
 * 설명: 수신고객목록 팝업 오픈
 * Return:
 *********************************************************/
 fn_OpenMSPCM128P(el, custListObj) {
   const lv_Vm = el
 
   // 팝업 기본 옵션 
   let popup128 = lv_Vm.$bottomModal.open(MSPCM128P, {
     properties: {
       pChnlCustIds: custListObj,
     },
     listeners: {
       // 리턴 데이터가 있는 팝업 닫기
       onPopupClose: (pData) => {
         // pData.rtnData = {smsTargetCustList, 'confirm', parentsViewId, mth}
             if (this.trSmsGb === '1') {    // '1'-더 헬스  .  '2'-영양분석 
                   lv_Vm.fn_healthCareLog('05')
             } else {
                   lv_Vm.fn_healthCareLog('03')
             }
         // iOS에서 문자 작성화면 이동 후 돌아오면 하단탭 사라지는 문제 대응
        // cmUtil.setSelectedCustList(lv_Vm, cmUtil.CUST_LIST)
         lv_Vm.$bottomModal.close(popup128)
       },
       // 단순 팝업 종료
       onPopupCancel: () => {
         lv_Vm.$bottomModal.close(popup128)
       },
       // 터치온 발송 프로세스
       onNextService: async (pData) => {
         await lv_Vm.$bottomModal.close(popup128)
         // pData.rtnData = {smsTargetCustList, 'confirm', parentsViewId, msg, isNotEditable, mth}
         let newCustListObj = pData.rtnData
         // 터치온 팝업 오픈(MSPCM154P)
         await lv_Vm.fn_OpenMSPCM154P(lv_Vm, newCustListObj)
       }
     }
   })
 },  
  /*********************************************************
  * Function명: fn_OpenMSPCM154P
  * 설명: 터치On 문자발송 팝업 오픈
  * 
  *********************************************************/
  fn_OpenMSPCM154P(el, custListObj) {
   const lv_Vm = el
   
   let popup154 = lv_Vm.$bottomModal.open(MSPCM154P, {
     properties: {
       pPage: custListObj.parentsViewId,                   // 모화면 페이지명
       pSmsTargetCustList: custListObj.smsTargetCustList,  // 수신고객리스트
       pMsg: custListObj.msg,                              // 선택된 상용구
       pIsNotEditable: custListObj.isNotEditable           // 메시지 수정가능여부
     },
     listeners: {
       onPopupClose: (pData) => {
         if (pData.str === 'confirm') {
             if (this.trSmsGb === '1') {    // '1'-더 헬스  .  '2'-영양분석 
                   lv_Vm.fn_healthCareLog('05')
             } else {
                   lv_Vm.fn_healthCareLog('03')
             }
           lv_Vm.getStore('toast').dispatch('ADD', '발송되었습니다.')
         }
         // 모달 닫기
         lv_Vm.$bottomModal.close(popup154)
       },
     }
   })
 } , 
    /******************************************************************************
     * Function명 : onClickSearch
     * 설명       : 고객명 재검색 버튼 클릭시  화면 컨트롤 값   초기화 처리
     ******************************************************************************/   
    onClickSearch () {
        this.radio = ''
        this.checkDisabled = true
        this.guideCheck = true
        this.radioCheck = true
    },
   /******************************************************************************
     * Function명 : fn_???close
     * 설명       : 버텀시트  close 정의 
     ******************************************************************************/
      fn_smsCheckclose () {      
        this.$bizUtil.showCustomBottomSheet(this, this.$refs.sms_nsbottomsheet, false)
      },    
     fn_eyeCheckclose () {      
       this.$bizUtil.showCustomBottomSheet(this, this.$refs.eye_nsbottomsheet, false)
       // 테스트 시작  버튼  버텀 싯트  제거 
       this.$bizUtil.showCustomBottomSheet(this, this.$refs.nsbottomsheet, false)

      }, 
     fn_mktCheckclose () {      
        this.$bizUtil.showCustomBottomSheet(this, this.$refs.mkt_nsbottomsheet, false)
      }, 
     fn_ecashCheckclose () {      
        this.$bizUtil.showCustomBottomSheet(this, this.$refs.ecash_nsbottomsheet, false)
      },             
   /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
      fn_HistoryBack () {      
        

        // 체크박스가 있는 경우
         console.log("fn_HistoryBack- 1  his.radioCheck >>" , this.radioCheck , this.radio )
         if (this.radio > '0'){
                 let alertMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'

                 let alertObj = this.$bottomModal.open(MSPBottomAlert, {
                   properties: {
                       type: 'B',
                       content: alertMsg,
                       title_pos_btn: "예",
                       title_neg_btn: "아니오"
                   },
                   listeners: {
                     onPopupConfirm: () => {
                      this.$bottomModal.close(alertObj)                
                       // 특정화면 이동 혹은 이전 화면 이동
        //               this.$router.go(-1)
                        this.$router.push({name: 'MSPBC002M'})  // 이전화면으로 이동
                    },
                     onPopupClose: () => {
                      this.$bottomModal.close(alertObj)
                     }
                  }
                })
         } else {
        //     this.$router.go(-1) // 이전화면으로 이동
                this.$router.push({name: 'MSPBC002M'})  // 이전화면으로 이동
          }
      },

    }
  };
</script>
<style scoped>
</style>

